import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import dayjs from 'dayjs';

import { validateInputPercentage } from 'utils/validation';
import { REGEXP } from 'consts';
import styled from '@emotion/styled';
import { Button, Dropdown, InputCurrency, Input, InputPercentage } from 'components';
import { RadioButton, InputDate } from '@xchange/uikit';
import { BuyProcessDeadlineData } from './types';
import useUserVendors from 'hooks/useUserVendors';
import clsx from 'clsx';

const STEP = 5;

interface BuyProcessFormDeadlineProps {
  defaultValues?: Partial<BuyProcessDeadlineData>;
  isEditing: boolean;
  onStartEdit: (step: number) => void;
  onSubmit: (values: BuyProcessDeadlineData) => void;
}

const BuyProcessFormDeadline: React.FC<BuyProcessFormDeadlineProps> = ({
  isEditing,
  onStartEdit,
  onSubmit,
  defaultValues
}) => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch
  } = useForm({
    defaultValues: { homeWarranty: 'Yes', ...defaultValues }
  });
  const [
    homeWarranty,
    deadlineDate,
    buyerAttorney,
    lender
  ] = watch(['homeWarranty', 'deadlineDate', 'BuyerAttorney', 'Lender']);
  const {
    getSelectedVendor,
    getSelectVendorOptions
  } = useUserVendors();
  const disableAttorney = buyerAttorney !== 'Other';
  const disableLender = lender !== 'Other';

  const attorneySelectOptions = useMemo(() => getSelectVendorOptions('attorneys', true), [getSelectVendorOptions]);
  const lenderSelectOptions = useMemo(() => getSelectVendorOptions('lenders', true), [getSelectVendorOptions]);

  const handleSelectVendor = (vendorId: number, vendorType: 'BuyerAttorney' | 'Lender') => {
    const vendorsType = vendorType === 'BuyerAttorney' ? 'attorneys' : 'lenders';
    const selectedVendor = getSelectedVendor(vendorId, vendorsType);

    setValue(`${vendorType}_name`, selectedVendor?.name || '');
    setValue(`${vendorType}_email`, selectedVendor?.email || '');
    setValue(`${vendorType}_phone`, selectedVendor?.phone || '');
  };

  if (!isEditing && defaultValues) {
    return (
      <div className="step-info">
        <div className="step-info__content">
          <h3 className="step-info__primary">Desired Closing Date: {defaultValues.deadlineDate}</h3>
          <span>Inspection: {defaultValues.inspectionChoice}</span>
          <span>Home Warranty: {defaultValues.homeWarranty}</span>
          <span>Warranty Price: {defaultValues.homeWarrantyPrice || '-'}</span>
          <span>Tax Proration: {defaultValues.taxProration || '-'}</span>
          <span>
            Attorney:{' '}
            {defaultValues.BuyerAttorney_name
              ? `${defaultValues.BuyerAttorney_name} • ${defaultValues.BuyerAttorney_email} • ${defaultValues.BuyerAttorney_phone}`
              : '-'}
          </span>
          <span>
            Lender:{' '}
            {defaultValues.Lender_name
              ? `${defaultValues.Lender_name} • ${defaultValues.Lender_email} • ${defaultValues.Lender_phone}`
              : '-'}
          </span>
        </div>
        <Button className="step-info__edit-btn" simple onClick={() => onStartEdit(STEP)}>
          Edit
        </Button>
      </div>
    );
  }

  return (
    <StyledForm className="deadline-form" onSubmit={handleSubmit(onSubmit)}>
      <p>When do you want to close?</p>
      <div className="deadline-form__input-group">
        <Controller
          control={control}
          rules={{
            required: 'Required',
            validate: {
              validDate: value => dayjs(value).isValid() || `Invalid date`,
              notPast: value =>
                dayjs().startOf('day').isSameOrBefore(dayjs(value)) || `Date can't be in the past`
            }
          }}
          name="deadlineDate"
          defaultValue=""
          render={({ field }) => (
            <InputDate
              inputProps={{
                label: 'Select Date*',
                placeholder: 'MM/DD/YYYY',
                error: errors.deadlineDate?.message
              }}
              className="deadline-form__input"
              {...field}
            />
          )}
        />
      </div>
      {deadlineDate && (
        <>
          <hr />
          <div className="offset-top" />
          <p>Inspection needed?</p>
          <div className="deadline-form__radio-group">
            <RadioButton
              {...register('inspectionChoice')}
              value="Yes"
              label="Yes"
              defaultChecked={!defaultValues?.inspectionChoice}
            />
            <RadioButton {...register('inspectionChoice')} value="No" label="No" />
            <RadioButton {...register('inspectionChoice')} value="As is" label="As is" />
          </div>
          <div className="offset-top" />
          <p>Post-closing possession?</p>
          <div className="deadline-form__radio-group">
            <RadioButton
              {...register('postClosingPoss')}
              value="Yes"
              label="Yes"
            />
            <RadioButton {...register('postClosingPoss')} value="No" label="No" defaultChecked={!defaultValues?.postClosingPoss} />
          </div>
          <div className="offset-top" />
          <p>Sale Contingency?</p>
          <div className="deadline-form__radio-group">
            <RadioButton
              {...register('saleContingency')}
              value="Yes"
              label="Yes"
            />
            <RadioButton {...register('saleContingency')} value="No" label="No" defaultChecked={!defaultValues?.saleContingency} />
          </div>
          <div className="offset-top" />
          <p>Home Warranty?</p>
          <div className="deadline-form__radio-group">
            <RadioButton {...register('homeWarranty')} value="Yes" label="Yes" />
            <RadioButton {...register('homeWarranty')} value="No" label="No" />
          </div>
          <div className="deadline-form__input-group">
            {homeWarranty === 'Yes' && (
              <Controller
                control={control}
                name="homeWarrantyPrice"
                render={({ field, formState: { errors } }) => (
                  <InputCurrency
                    label="Home Warranty Price"
                    placeholder="Enter Home Warranty Price"
                    min={0}
                    error={errors.homeWarrantyPrice?.message}
                    className="deadline-form__input"
                    {...field}
                  />
                )}
              />
            )}
            <Controller
              control={control}
              rules={{
                validate: value => validateInputPercentage(value, 0, 10000)
              }}
              name="taxProration"
              defaultValue=""
              render={({ field, formState: { errors } }) => (
                <InputPercentage
                  label="Tax proration"
                  placeholder="Enter Percentage"
                  error={errors.taxProration?.message}
                  {...field}
                />
              )}
            />
          </div>
          <div className="deadline-form__input-group">
            <Controller
              control={control}
              name="BuyerAttorney"
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  options={attorneySelectOptions}
                  onChange={value => {
                    handleSelectVendor(value, 'BuyerAttorney');
                    onChange(value);
                  }}
                  label="Select Attorney"
                  placeholder="Select Attorney"
                  className="deadline-form__input"
                  {...field}
                />
              )}
            />
            <Input
              className={clsx("deadline-form__input", { 'read-only': disableAttorney })}
              {...register('BuyerAttorney_name', {
                pattern: { value: REGEXP.NAME, message: 'Invalid name' }
              })}
              error={errors.BuyerAttorney_name?.message}
              label="Attorney Name"
              placeholder="Enter Attorney Name"
            />
          </div>

          <div className="deadline-form__input-group">
            <Input
              {...register('BuyerAttorney_email', {
                pattern: { value: REGEXP.EMAIL, message: 'Invalid email address' }
              })}
              error={errors.BuyerAttorney_email?.message}
              className={clsx("deadline-form__input", { 'read-only': disableAttorney })}
              label="Attorney Email"
              placeholder="Enter Attorney Email"
            />
            <Controller
              control={control}
              name="BuyerAttorney_phone"
              rules={{
                validate: value => !value?.includes('_') || 'Invalid format'
              }}
              defaultValue=""
              render={({ field, formState: { errors } }) => (
                <Input
                  className={clsx("deadline-form__input", { 'read-only': disableAttorney })}
                  as={MaskedInput}
                  mask="(999) 999-9999"
                  type="tel"
                  error={errors.BuyerAttorney_phone?.message}
                  label="Attorney Phone"
                  placeholder="Enter Attorney Phone"
                  {...field}
                />
              )}
            />
          </div>
          <div className="deadline-form__input-group">
            <Controller
              control={control}
              name="Lender"
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  options={lenderSelectOptions}
                  onChange={value => {
                    handleSelectVendor(value, 'Lender');
                    onChange(value);
                  }}
                  label="Select Lender"
                  placeholder="Select Lender"
                  className="deadline-form__input"
                  {...field}
                />
              )}
            />
            <Input
              className={clsx("deadline-form__input", { 'read-only': disableLender })}
              {...register('Lender_name')}
              error={errors.Lender_name?.message}
              label="Lender Name"
              placeholder="Enter Lender Name"
            />
          </div>

          <div className="deadline-form__input-group">
            <Input
              {...register('Lender_email', {
                pattern: { value: REGEXP.EMAIL, message: 'Invalid email address' }
              })}
              error={errors.Lender_email?.message}
              className={clsx("deadline-form__input", { 'read-only': disableLender })}
              label="Lender Email"
              placeholder="Enter Lender Email"
            />
            <Controller
              control={control}
              name="Lender_phone"
              defaultValue=""
              rules={{
                validate: value => !value?.includes('_') || 'Invalid format'
              }}
              render={({ field, formState: { errors } }) => (
                <Input
                  className={clsx("deadline-form__input", { 'read-only': disableLender })}
                  as={MaskedInput}
                  mask="(999) 999-9999"
                  type="tel"
                  error={errors.Lender_phone?.message}
                  label="Lender Phone"
                  placeholder="Enter Lender Phone"
                  {...field}
                />
              )}
            />
          </div>
          <Button className="deadline-form__submit-btn">Next</Button>
        </>
      )}
    </StyledForm>
  );
};

export default BuyProcessFormDeadline;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  & > * {
    margin: 0 0 12px 0;
  }

  .input,
  .dropdown {
    &__component {
      height: 40px;
    }
    label {
      font-size: 10px;
      line-height: 16px;
    }
  }

  .deadline-form__input-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 20px;
  }
  .deadline-form__input {
    width: 100%;
    .dropdown__button {
      height: 40px;
    }
  }

  .deadline-form__radio-group {
    & > .label {
      margin-bottom: 8px;
    }
    .radiobutton {
      margin-right: 20px;
    }
    .button-like .label {
      white-space: nowrap;
    }
  }
  .deadline-form__description {
    color: ${props => props.theme.colors.cloudGray};
    margin: 6px 0 0;
    font-size: 12px;
    line-height: 16px;
  }

  .read-only {
    input {
      background: ${props => props.theme.colors.lightGray};
      color: ${props => props.theme.colors.gray};
      pointer-events: none;

      &::placeholder {
        color: ${props => props.theme.colors.gray};
      }
    }
  }

  hr {
    border-style: dashed;
    border-color: #c4c4c4;
    margin: 20px 0;
  }

  .deadline-form__submit-btn {
    width: 112px;
    margin: 8px 0 0 auto;
    flex-shrink: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .deadline-form__input-group {
      grid-template-columns: 1fr;
      grid-gap: 12px 0;
    }
    .deadline-form__submit-btn {
      width: 100%;
    }
  }
`;
