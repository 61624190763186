import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import notify from 'notify';
import dayjs from 'dayjs';

import useLayout from 'hooks/useLayout';
import { Button, Icon } from '@xchange/uikit';
import { tryGetFirstError } from 'utils/requests';
import { getListingAddress } from 'utils/listing';
import { camelToNormal } from 'utils/string';
import WorkspaceContext from 'workspace/WorkspaceContext';
import {
  getTransactionalData as getTransactionalDataRequest,
  updateTransactionalData as updateTransactionalDataRequest,
  addExpense as addExpenseRequest,
  deleteExpense as deleteExpenseRequest,
  getTransactionalDataXP
} from '../api';
import { formatCurrency } from 'utils/rifmFormatters';
import TransactionalDataForm from './TransactionalDataForm';
import EarnestMoneyForm from './EarnestMoneyForm';
import Expenses from './Expenses';
import ExpenseForm from './ExpenseForm';

const getDueAmount = (total, paid) => {
  return Number(total) - Number(paid);
};

const getDateLabel = (date?: number) => {
  if (!date || !dayjs(date).isValid()) return 'N/A';
  const className = dayjs(date).isBefore(dayjs(), 'day') ? 'past' : 'future';
  return <span className={className}>{dayjs(date).format('MM/DD/YYYY')}</span>;
};

const WorkspaceTransactionsTab: React.FC = () => {
  const { listing, listingId, isLinkedListing } = useContext(WorkspaceContext);
  const [transactionalData, setTransactionalData] = useState<any>({});
  const [overwiteData, setOverwiteData] = useState<any>({});
  const [openTransactionalDataForm, setOpenTransactionalDataForm] = useState<boolean>(false);
  const [openEmForm, setOpenEmForm] = useState<boolean>(false);
  const [openExpenseForm, setOpenExpenseForm] = useState<boolean>(false);
  const layout = useLayout();

  const {
    address1, address2, city, county, state, zip
  } = listing || {} as Listing;
  const listingAddressValues = {
    address1, address2, city, county, state, zip
  };

  const { earnest_money_1_deposit, earnest_money_2_deposit, earnest_money_3_deposit, earnest_money_4_deposit, earnest_money_1_deposited, earnest_money_2_deposited, earnest_money_3_deposited, earnest_money_4_deposited } = transactionalData;
  const extrasDue = getDueAmount(transactionalData.extras_total, transactionalData.extras_paid);
  const emTotal = earnest_money_1_deposit + earnest_money_2_deposit + earnest_money_3_deposit + earnest_money_4_deposit;
  const emTotalDeposited = (earnest_money_1_deposit * earnest_money_1_deposited) + (
    earnest_money_2_deposit * earnest_money_2_deposited
  ) + (earnest_money_3_deposit * earnest_money_3_deposited) + (earnest_money_4_deposit * earnest_money_4_deposited);
  const emDue = getDueAmount(emTotal, emTotalDeposited);

  const getTransactionalData = async (listingId: string) => {
    try {
      const { data, expenses } = await getTransactionalDataRequest(listingId);
      setTransactionalData({ ...data, expenses });
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const handleGetXpData = async (listingId:string) => {
    try {
      var xpData = await getTransactionalDataXP(listingId);
      var tempData = {};
      for (const key in transactionalData) {
        tempData[key] = transactionalData[key];
        if(!tempData[key]){
          if (key in xpData){
            tempData[key] = xpData[key];
          }  
        }
      }
      setOverwiteData(tempData);
      setOpenTransactionalDataForm(true);
    } catch (err) {
      notify("Error getting synced data");
    }
  };

  const updateTransactionalData = async (
    values: { [key: string]: number | string; },
    closeForm?: (value: boolean) => void) => {
    try {
      await updateTransactionalDataRequest(values, listingId,transactionalData);
      getTransactionalData(listingId);
      closeForm?.(false);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  function openEditForm(){
    setOpenTransactionalDataForm(true);
    setOverwiteData({});
  }

  const addExpense = async (values: {
    expense: number;
    expense_description: string;
  }) => {
    try {
      await addExpenseRequest(values, listingId);
      getTransactionalData(listingId);
      setOpenExpenseForm(false);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const deleteExpense = async (expenseId: number) => {
    try {
      await deleteExpenseRequest(expenseId);
      getTransactionalData(listingId);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  useEffect(() => {
    getTransactionalData(listingId);
  }, [listingId]);



  return (
    <StyledWorkspaceTransactionsTab className="transactions">
      <div className="transactions-info">
        <div className="transactions-info__header">
          <h4>Data</h4>
          <div>
            {!openTransactionalDataForm && isLinkedListing && (
              <Button onClick={() => handleGetXpData(listingId)} className="members__btn sync_xp_btn">
                {layout === 'mobile' ? 'XP' : `Sync with XP`}
              </Button>
            )}
            {!openTransactionalDataForm && (
              <Button onClick={() => openEditForm()} className="edit-btn">
                {layout === 'mobile' ? <Icon name="pen" /> : `Edit`}
              </Button>
            )}
          </div>
        </div>

        <hr />

        {openTransactionalDataForm ? (
          <TransactionalDataForm
            data={{ ...(Object.keys(overwiteData).length === 0 ? transactionalData : overwiteData), ...listingAddressValues }}
            onCancel={() => setOpenTransactionalDataForm(false)}
            onSubmit={values => updateTransactionalData(values, setOpenTransactionalDataForm)}
          />
        ) : (
            <>
              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Address</div>
                <div className="transactions-info__row-value">
                  {getListingAddress(listing!)}
                </div>
              </div>
              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Project Type</div>
                <div className="transactions-info__row-value">
                  {transactionalData.property_type}
                </div>
              </div>

              <hr />

              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Listing Price</div>
                <div className="transactions-info__row-value">
                  {formatCurrency(transactionalData.current_price) || 'N/A'}
                </div>
              </div>
              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Contract Purchase</div>
                <div className="transactions-info__row-value">
                  {formatCurrency(transactionalData.contract_price) || 'N/A'}
                </div>
              </div>

              <hr />

              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Effective Date</div>
                <div className="transactions-info__row-value">
                  {getDateLabel(transactionalData.effective_date)}
                </div>
              </div>
              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Attorney Review Deadline</div>
                <div className="transactions-info__row-value">
                  {getDateLabel(transactionalData.attorney_review_deadline)}
                </div>
              </div>
              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Inspection Deadline </div>
                <div className="transactions-info__row-value">
                  {getDateLabel(transactionalData.inspection_due_deadline)}
                </div>
              </div>
              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Mortgage Cont. Deadline </div>
                <div className="transactions-info__row-value">
                  {getDateLabel(transactionalData.mortgage_contingency_cleartoclose_deadline)}
                </div>
              </div>
              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Contract Closing Date</div>
                <div className="transactions-info__row-value">
                  {getDateLabel(transactionalData.closing_date)}
                </div>
              </div>

              <hr />

              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Earnest Money</div>
                <div className="amount">
                  <div>
                    Total:&nbsp;
                  <span className="amount-total">
                      {formatCurrency(emTotal)}
                    </span>
                  </div>
                  <div>
                    Deposited:&nbsp;
                  <span className="amount-total">
                      {formatCurrency(emTotalDeposited)}
                    </span>
                  </div>
                  <div>
                    Due:&nbsp;
                  <span className="amount-due">
                      {formatCurrency(emDue)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Extras</div>
                <div className="amount">
                  <div>
                    Total:&nbsp;
                  <span className="amount-total">
                      {formatCurrency(transactionalData.extras_total) || '$0'}
                    </span>
                  </div>
                  <div>
                    Paid:&nbsp;
                  <span className="amount-total">
                      {formatCurrency(transactionalData.extras_paid) || '$0'}
                    </span>
                  </div>
                  <div>
                    Due:&nbsp;
                  <span className="amount-due">
                      {formatCurrency(extrasDue)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Closing Cost Credit</div>
                <div className="transactions-info__row-value">
                  {formatCurrency(transactionalData.closing_cost_credits)}
                </div>
              </div>
            </>
          )}

      </div>

      <div className="transactions-info">
        <div className="transactions-info__header">
          <h4>Earnest Money Break Down</h4>
          {!openEmForm && (
            <Button onClick={() => setOpenEmForm(true)} className="edit-btn">
              {layout === 'mobile' ? <Icon name="pen" /> : `Edit`}
            </Button>
          )}
        </div>

        <hr />

        {openEmForm ? (
          <EarnestMoneyForm
            data={(Object.keys(overwiteData).length === 0 ? transactionalData : overwiteData)}
            onCancel={() => setOpenEmForm(false)}
            onSubmit={values => updateTransactionalData(values, setOpenEmForm)}
          />
        ) : (
            <>
              <div className="transactions-info__row">
                <div className="transactions-info__row-name">Held by:</div>
                <div className="transactions-info__row-value">
                  {camelToNormal(transactionalData.em_role_specific) || 'N/A'}
                </div>
              </div>
              {earnest_money_1_deposit ? (
                <div className="transactions-info__row">
                  <div className="transactions-info__row-name">1st Installment:</div>
                  <div className="transactions-info__row-value">
                    {formatCurrency(earnest_money_1_deposit) || 'N/A'}
                  </div>
                  <div className="deposit">
                    {earnest_money_1_deposited ? 'Deposited' : ''}
                  </div>
                </div>
              ) : null}
              {earnest_money_2_deposit ? (
                <div className="transactions-info__row">
                  <div className="transactions-info__row-name">2nd Installment:</div>
                  <div className="transactions-info__row-value">
                    {formatCurrency(earnest_money_2_deposit) || 'N/A'}
                  </div>
                  <div className="deposit">
                    {earnest_money_2_deposited ? 'Deposited' : ''}
                  </div>
                </div>
              ) : null}
              {earnest_money_3_deposit ? (
                <div className="transactions-info__row">
                  <div className="transactions-info__row-name">3rd Installment:</div>
                  <div className="transactions-info__row-value">
                    {formatCurrency(earnest_money_3_deposit) || 'N/A'}
                  </div>
                  <div className="deposit">
                    {earnest_money_3_deposited ? 'Deposited' : ''}
                  </div>
                </div>
              ) : null}
              {earnest_money_4_deposit ? (
                <div className="transactions-info__row">
                  <div className="transactions-info__row-name">4th Installment:</div>
                  <div className="transactions-info__row-value">
                    {formatCurrency(earnest_money_4_deposit) || 'N/A'}
                  </div>
                  <div className="deposit">
                    {earnest_money_4_deposited ? 'Deposited' : ''}
                  </div>
                </div>
              ) : null}
            </>
          )}
      </div>

      <div className="transactions-info">
        <div className="transactions-info__header">
          <h4>Expenses Overview</h4>
        </div>

        <hr />

        <Expenses
          items={transactionalData.expenses}
          onDelete={deleteExpense}
        />

        {!openExpenseForm && (
          <div className="transactions-info__row">
            <Button
              onClick={() => setOpenExpenseForm(true)}
              simple
              className="add-btn"
            >
              + Add Expense
          </Button>
          </div>
        )}

        {openExpenseForm && (
          <ExpenseForm
            onCancel={() => setOpenExpenseForm(false)}
            onSubmit={addExpense}
          />
        )}
      </div>
    </StyledWorkspaceTransactionsTab>
  );
};

export default WorkspaceTransactionsTab;

const StyledWorkspaceTransactionsTab = styled.div`
  .sync_xp_btn{margin-right: 10px;}
  .transactions {
    &-info {
      margin: 24px 0;
      display: grid;
      grid-row-gap: 12px;
    }
    &-info__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h4 {
        margin: 0;
        font-weight: normal;
        line-height: 16px;
        color: ${props => props.theme.colors.grayDark};
      }
      .button {
        min-width: 64px;
      }
    }
    &-info__row {
      display: grid;
      grid-column-gap: 12px;
      grid-template-columns: 180px 1fr 1fr 1fr;
      &-name {
        font-weight: 500;
      }
    }
  }

  .past {
    color: ${props => props.theme.colors.red};
  }

  .future {
    color: ${props => props.theme.colors.lightGreen};
  }

  .amount {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: span 3;
    color: ${props => props.theme.colors.grayDark};
    &-total {
      color: ${props => props.theme.colors.lightGreen};
    }
    &-due {
      color: ${props => props.theme.colors.red};
    }
  }

  .deposit {
    color: ${props => props.theme.colors.grayDark};
  }

  .add-btn {
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.colors.red};
    justify-content: flex-start;
  }

  hr {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.seashell};;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .transactions {
      &-info__row {
        grid-template-columns: 1fr 1fr;
      }
      &-info__header {
        .button {
          min-width: 32px;
          width: 32px;
        }
      }
    }
    .amount {
      margin-bottom: 4px;
      grid-column: span 1;
      grid-template-columns: none;
      grid-row-gap: 12px;
    }
    .deposit {
      margin-top: 4px;
      margin-left: 50%;
      padding-left: 6px;
      grid-column: span 2;
    }
  }
`;


