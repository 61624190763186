import React, { useState } from 'react';
import useLayout from 'hooks/useLayout';
import styled from '@emotion/styled';
import { ModalConfirm } from 'components';
import {Button, Input } from '@xchange/uikit';
import WorkspaceNote from '../WorkspaceNotesTab/WorkspaceNote';
import useNotes from '../WorkspaceNotesTab/useNotes';

const WorkspaceNotesTab = () => {
  const {
    listingId,
    notes,
    isAddingNote,
    setIsAddingNote,
    addNoteText,
    setAddNoteText,
    removeNote,
    submitNewNote,
    cancelAddNote,
  } = useNotes();
  const [deleteNote, setDeleteNote] = useState<number>();
  const layout = useLayout();

  if (!listingId) return null;

  return (
    <StyledWorkspaceNotesTab>
      {layout === 'mobile' && (
        <h3 style={{marginTop: '24px'}}>Notes</h3>
      )}
      {notes && notes.length !== 0 && (
        <>
          {notes?.map(item => (
            <WorkspaceNote
              key={item.id}
              data={item}
              onDelete={setDeleteNote}
              disableDelete={Boolean(deleteNote)}
            />
          ))}
        </>
      )}
      {isAddingNote ? (
        <form className="add-note" onSubmit={submitNewNote}>
          <Input
            as="textarea"
            value={addNoteText}
            onChange={e => setAddNoteText(e.target.value)}
            required
          />
          <Button secondary type="button" onClick={cancelAddNote}>
            Cancel
          </Button>
          <Button onClick={submitNewNote}>Add</Button>
        </form>
      ) : (
          <Button link onClick={() => setIsAddingNote(true)}>
            + Add note
          </Button>
        )}
      {!notes ||
        (notes.length === 0 && <div className="not-found-message notes">No notes yet.</div>)}

      <ModalConfirm
        modalTitle="Delete Note"
        open={Boolean(deleteNote)}
        onSubmit={async () => {
          await removeNote(deleteNote, () => setDeleteNote(undefined));
        }}
        onClose={() => setDeleteNote(undefined)}>
        <p>Are you sure you want to delete this note? This action can’t be undone.</p>
      </ModalConfirm>
    </StyledWorkspaceNotesTab>
  );
};

export default WorkspaceNotesTab;

const StyledWorkspaceNotesTab = styled.div`
  margin-top: 0px;
  padding: 0px 15px;

  .add-note {
    .input {
      width: 100%;
      margin-bottom: 12px;

      textarea {
        width: 100%;
        resize: none;
      }
    }

    .button {
      margin-right: 8px;
    }
  }

  .add-task-button {
    float: right;
  }
  .not-found-message {
    margin: 64px 0;
  }

  h3 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
  }

  & > .button.link {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .table {
    display: grid;
    column-gap: 12px;
    font-size: 12px;
    overflow-x: auto;
    box-sizing: border-box;
    width: 100%;

    &__header {
      padding: 8px 0;
      grid-column: 1 / span 6;
      display: grid;
      grid-template-columns: 275px 200px 60px 100px 100px 1fr;
      column-gap: 12px;
      border-bottom: 1px solid #dadada;
    }
  }
`;

const StyledTableRow = styled.div`
  margin-top: 8px;
  grid-column: 1 / span 6;
  display: grid;
  grid-template-columns: 275px 200px 60px 100px 100px 1fr;
  column-gap: 12px;
  font-size: 12px;
  height: 24px;
  border-radius: 3px;

  &:hover {
    background: #f1f1f1;
  }

  .col {
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.title {
      position: relative;
      padding-left: 16px;

      &::before {
        background: #000;
        content: '';
        position: absolute;
        width: 4px;
        left: 4px;
        top: 4px;
        bottom: 4px;
        border-radius: 4px;
      }
    }
  }

  .title-text {
    overflow: hidden;
    text-overflow: ellipsis;

    .xp-tag {
      color: ${props => props.theme.colors.red};
    }
  }

  &.task .col.title::before {
    background: ${props => props.theme.colors.lightGreen};
  }

  &.deadline {
    .col {
      &.title::before {
        background: ${props => props.theme.colors.red};
      }
      &.date {
        color: ${props => props.theme.colors.lightGreen};
        &.past {
          color: ${props => props.theme.colors.red};
        }
      }
    }
  }

  &.event .col.title::before {
    background: ${props => props.theme.colors.blue};
  }

  &.event .title-text {
    padding: 0 4px;
    background: #2979ff;
    color: #fff;
    border-radius: 3px;
    width: 100%;
  }

  &.completed {
    .title,
    .assignee,
    .date,
    .location {
      text-decoration: line-through;
    }

    .button-complete {
      .icon {
        fill: #62b120;
      }
    }
  }

  .icon,
  .indicator {
    vertical-align: text-bottom;
  }

  .icon {
    fill: #8b8b8b;
  }

  .indicator {
    display: inline-block;
    height: 16px;
    width: 4px;
    background: #62b120;
    border-radius: 4px;
    margin-right: 8px;
  }

  .menu-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 24px;
  }

  .button-order {
    cursor: pointer;

    &.accepted {
      color: ${props => props.theme.colors.lightGreen};
    }

    &:disabled {
      background: none;
    }
  }

  .button {
    fill: ${props => props.theme.colors.graphite};
    margin-right: 16px;

    &.button-complete {
      &:focus {
        opacity: 1;
      }
    }

    &.button-delete:hover,
    &.button-edit:hover {
      background: ${props => props.theme.colors.graphite};
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .col.actions {
      overflow: initial;
    }
  }
`;
